// Stylesheets
import "./theme.scss";

// Favicons
import "./resources/favicons/apple-touch-icon.png"
import "./resources/favicons/favicon-32x32.png"
import "./resources/favicons/favicon-16x16.png"
import "./resources/favicons/safari-pinned-tab.svg"

// contact button
// import "./site/utility.js"

// Component Javascript
import "./components/experiencefragment/navbar.js";
import "./components/experiencefragment/insta_feed.js";
import "./components/experiencefragment/smooth_scroll.js";   
import "./components/experiencefragment/social_menu.js";

// import "./components/experiencefragment/iframe_embed.js";
import "./components/experiencefragment/age-gate.js";  