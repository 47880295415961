let navbarToggle = document.querySelector(".navbar-toggle");
let navbarMenu = document.querySelector(".nav-container");
let menuItemDropdownToggles = document.querySelectorAll(".nav-container .li-dropdown");
let menuItemDropdownToggleLinks = document.querySelectorAll(".nav-container .li-dropdown > a");

let navLinks = document.querySelectorAll(".nav-container a");

navbarToggle.addEventListener("click", function () {
  if (navbarToggle.classList.contains("collapsed")) {
    navbarToggle.classList.remove("collapsed");
    navbarToggle.classList.add("open");
    navbarMenu.classList.add("open");
  } else {
    navbarToggle.classList.add("collapsed");
    navbarToggle.classList.remove("open");
    navbarMenu.classList.remove("open");
    menuItemDropdownToggles.forEach(toggle => {
      toggle.classList.remove("open");
      toggle.querySelector(".ul-dropdown").classList.remove("open");
    });
  }
});

menuItemDropdownToggleLinks.forEach((link, index) => {
  link.addEventListener("click", function (e) {
    if (navbarMenu.classList.contains("open")) {
      e.preventDefault();
      const parentDropdown = menuItemDropdownToggles[index];
      const isOpen = parentDropdown.classList.contains("open");
      
      menuItemDropdownToggles.forEach(toggle => {
        toggle.classList.remove("open");
        toggle.querySelector(".ul-dropdown").classList.remove("open");
      });

      if (!isOpen) {
        parentDropdown.classList.add("open");
        parentDropdown.querySelector(".ul-dropdown").classList.add("open");
      }
    }
  });
});

window.addEventListener("resize", function () {
  if (window.innerWidth <= 991) {
    if (!navbarMenu.classList.contains("collapsed")) {
      navbarMenu.classList.add("collapsed");
      navbarToggle.classList.add("collapsed");
      navbarToggle.classList.remove("open");
    }
  }
});
