function setupSocialMenu() {
  // Track the currently open menu element (if any)
  let openMenu = null;

  // Define the social icons and their corresponding menu identifiers.
  const socialItems = [
    { iconId: "social-footer-instagram", menuKey: "instagram" },
    { iconId: "social-footer-youtube", menuKey: "youtube" },
    { iconId: "social-footer-facebook", menuKey: "facebook" } // Added Facebook item
  ];

  socialItems.forEach(item => {
    const icon = document.getElementById(item.iconId);
    const menu = document.querySelector(`[data-social-menu="${item.menuKey}"]`);

    // Only attach the event listener if both the icon and the corresponding menu exist.
    if (icon && menu) {
      icon.addEventListener("click", function(event) {
        // Prevent the default behavior (e.g., navigation) of any child anchor tag.
        event.preventDefault();
        // Stop the click from propagating to the document.
        event.stopPropagation();

        // Toggle logic: if the corresponding menu is already open, hide it.
        if (openMenu === menu) {
          menu.classList.add("visually-hidden");
          openMenu = null;
        } else {
          // If another menu is open, hide it first.
          if (openMenu) {
            openMenu.classList.add("visually-hidden");
          }
          // Show the clicked menu.
          menu.classList.remove("visually-hidden");
          openMenu = menu;
        }

        // Scroll to the bottom of the page so the footer is visible.
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      });
    }
  });

  // Global listener to hide any open menu when clicking outside.
  document.addEventListener("click", function() {
    if (openMenu) {
      openMenu.classList.add("visually-hidden");
      openMenu = null;
    }
  });
}

// Initialize the social menu functionality.
setupSocialMenu();
